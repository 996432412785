
import { LoaderFunctionArgs, Params, redirect, useLoaderData } from "react-router-dom";
import styles from "@/views/portal/portal.module.scss";
import { useTranslation } from "react-i18next";
import Markdown from "marked-react";
import { PortalConfiguration } from "@/Interfaces";


interface Args extends LoaderFunctionArgs {
    params: Params<string>;
}

export const trophiesLoader = async ({ params }: Args) => {
    try {
        const { courseId } = params;
        const temp = sessionStorage.getItem('PORTAL_CONFIG')
        if (!courseId || !temp) throw new Error();
        
        const config: PortalConfiguration = JSON.parse(temp);

        if (!config.configuration.isTrophyShopEnabled) {
            return redirect(`/portal/${courseId}`);
        }

        // const response = await getSomething;

        // if (response.status !== "success") throw new Error();
        // return response.data;
        return null;
    } catch (error) {
        throw new Error(); // TODO: Error messages
    }
};

const Trophies = () => {
    const trophiesData = useLoaderData();
    const { t } = useTranslation();

    return (
        <>
            <header>
                <div>
                    <div className={styles.headerBox}>
                        <h4>{t("trophies")}</h4>
                        <div>
                            <img src="/assets/thumbnails/trophies.svg" alt="" width="142px" height="142px" />
                        </div>
                    </div>
                </div>
            </header>

            <div className={styles.menuContainer}>
                <div className={styles.sortContainer}>
                    <h6>{t("collectedDiamonds")}</h6>
                    <div className={styles.innerContainer}>
                        <div>
                            <div className={styles.imageFrame}>
                                <img src="/assets/trophies/ruby.svg" alt="" width="34px" height="34px" />
                            </div>
                            <b>{t("itemCount", { count: 2 })}</b>
                        </div>

                        <div>
                            <div className={styles.imageFrame}>
                                <img src="/assets/trophies/diamond.svg" alt="" width="34px" height="40px" />
                            </div>
                            <b>{t("itemCount", { count: 2 })}</b>
                        </div>
                    </div>
                </div>
                <hr />
                <div className={styles.sortContainer}>
                    <h6>{t("whatAreDiamonds")}</h6>
                    <div className={styles.innerContainer}>
                        <div className={styles.infoContainer}>
                            <Markdown>{t("trophiesInfo.part1")}</Markdown>
                            <div>
                                <img src="/assets/trophies/ruby.svg" alt="" width="34px" height="34px" />
                                <div>
                                    <Markdown>
                                        {t("trophiesInfo.rubies")}
                                    </Markdown>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/trophies/diamond.svg" alt="" width="34px" height="40px" />
                                <div>
                                    <Markdown>
                                        {t("trophiesInfo.diamonds")}
                                    </Markdown>
                                </div>
                            </div>
                            <Markdown>{t("trophiesInfo.part2")}</Markdown>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};

export default Trophies;