import styles from "@/components/sections/questionForm/questionForm.module.scss";
import { Answer, Question } from "@/Interfaces";
import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface NumberQuestionProps {
    question: Question | null;
    answer: Answer | null,
    answerArr: string[],
    setAnswerArr: (arr: string[]) => void;
}

const NumberQuestion: React.FC<NumberQuestionProps> = ({ question, answer, answerArr, setAnswerArr }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (answer) {
            setAnswerArr(answer.answer);
        }
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAnswerArr([e.target.value]);
    };

    return (
        question &&
        <>
            <div className={styles.answers}>
                <input type="number" value={answerArr} placeholder={t('answerHere')} className={styles.answer} onChange={handleChange}></input>
            </div>
        </>
    );
};

export default NumberQuestion;