import styles from "./root.module.scss";
import { Outlet, useRevalidator, } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { DEFAULT_LANGUAGE, getLocale, setLocale } from "@/locales/locales";
import MainMenu from "@/components/mainMenu/MainMenu";
import { changeLanguageFunction } from "@/Interfaces";

export const rootLoader = async (): Promise<any> => {
    try {
        return null;
    } catch (error) {
        throw new Error(); // TODO: Error messages
    }
};


const Root = () => {
    const [supportedlanguages, setSupportedLanguages] = useState<string[] | null>(null);
    const [lang, setLang] = useState<string>();
    const ref = useRef(null) as React.RefObject<HTMLDivElement>;
    const revalidator = useRevalidator();
    const locale = useCallback<changeLanguageFunction>(async (lang: string) => {
        await setLocale(lang);
        setLang(lang);
        revalidator.revalidate();
    }, [lang]);

    useEffect(() => {
        const locale = getLocale();

        if (!supportedlanguages) return;
        if (supportedlanguages.includes(locale)) {
            setLang(locale);
        } else {
            setLocale(DEFAULT_LANGUAGE);
            setLang(DEFAULT_LANGUAGE);
        }
    }, [lang, supportedlanguages]);

    return (
        <>
            <div className={styles.container}>
                <button type="button" data-bs-toggle="offcanvas" data-bs-target="#mainMenu" aria-controls="mainMenu">
                    <span className="material-symbols-rounded q_white">
                        menu
                    </span>
                </button>
            </div>
            <MainMenu supportedlanguages={supportedlanguages} refProp={ref} locale={locale} lang={lang} />
            <Outlet context={{ lang, setSupportedLanguages }} />
        </>
    );
};


export default Root;