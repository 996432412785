import { BookPopup } from "@/Interfaces";
import Markdown from "marked-react";
import styles from "./book.module.scss";
import cn from "classnames";


interface PopUpProps {
    popup: BookPopup;
}
const PopUp: React.FC<PopUpProps> = ({ popup }) => {
    return (
        <div className={cn(styles.paragraph, styles.popup)}>
            <Markdown>{popup.title}</Markdown>
            <Markdown>{popup.text}</Markdown>
        </div>
    );
};

export default PopUp;