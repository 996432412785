import { GroupData } from "@/Interfaces";
import { Link, LoaderFunctionArgs, Params, useLoaderData } from "react-router-dom";
import styles from "@/views/portal/portal.module.scss";
import MenuButton from "@/components/menuButton/MenuButton";
import ModalButton from "@/components/modal/ModalButton";
import { getGroupData } from "@/utils/waApiClient";
import { useTranslation } from "react-i18next";
import cn from 'classnames';
import NotificationModal from "@/components/modal/NotificationModal";
import { useEffect, useState } from "react";
import { LESSON_GROUP_TYPE } from "@/constants";

interface Args extends LoaderFunctionArgs {
    params: Params<string>;
}

interface showCongratulationModal {
    show: boolean;
    type: number;
    firstName: string;
    passed?: boolean;
}

export const groupLoader = async ({ params }: Args) => {
    const { id } = params;
    const { courseId } = params;
    const { drivingLicense} = params

    if (!id || !courseId || !drivingLicense) throw new Error();
    const response = await getGroupData({ id, courseId, drivingLicense });
    sessionStorage.setItem('COURSE_GROUP_ID', id);

    return response.data;
};

const Group = () => {
    const [showCongratulationModal, setShowCongratulationModal] = useState<showCongratulationModal | null>(null);
    const groupData = useLoaderData() as GroupData;
    const { lessons } = groupData;
    const { group } = groupData;
    const { t } = useTranslation();
    const courseId = sessionStorage.getItem('COURSE_ID');
    const drivingLicense = sessionStorage.getItem("DRIVING_LICENSE");

    useEffect(() => {
        const congratulationModal = sessionStorage.getItem('showCongratulationModal');

        if (congratulationModal) {
            setShowCongratulationModal(JSON.parse(congratulationModal));
        }

        return () => {
            sessionStorage.removeItem('showCongratulationModal');
        };
    }, []);

    return (
        <>
            <header style={{ backgroundImage: `url(${group.media.heroImage.url})` }}>
                <Link to={`/portal/${courseId}/${drivingLicense}`} className={styles.return}>
                    <span className={cn(styles.mobile, "material-symbols-rounded q_white")}>
                        chevron_left
                    </span>
                    <div className={cn(styles.desktop)}>
                        <div >
                            <span className="material-symbols-rounded">
                                keyboard_return
                            </span>
                        </div>
                        <span>{t('goBack')}</span>
                    </div>
                </Link>
                <div>
                    <div className={styles.headerBox}>
                        <div>
                            <img src={group.media.thumbnail.url} alt="" width="142px" height="142px" />
                        </div>
                        <h4>{group.name}</h4>
                    </div>
                </div>
            </header>

            <div className={styles.menuContainer}>
                {group.media.showcaseVideo.url ? <ModalButton title={t("introductionVideo")} icon="ondemand_video" type="video" videoUrl={group.media.showcaseVideo.url} /> : <div />}
                <div className={styles.orderLast}>
                    {lessons.exam.length !== 0 ?
                        <>
                            <hr />
                            {lessons.exam.map(item => (
                                <MenuButton key={item.id} title={t("lesson.exam")} link={`/lesson/${item.id}`} icon="trophy" />
                            ))}
                        </>
                        : <div />

                    }
                </div>

                {lessons.basic.length !== 0 &&
                    <>
                        <hr />
                        <div className={cn(styles.sortContainer, {
                            [styles.colSpan]: lessons.extra.length === 0
                        })}>
                            <h6>{t("lesson.basic")} </h6>
                            <div className={styles.buttonContainer}>
                                {lessons.basic.map(item => (
                                    <MenuButton key={item.id} title={item.name} link={`/lesson/${item.id}`} progress={item.completionPercentage} icon={item.completionPercentage === 100 ? "quiz" : "quiz"} disabled={item.isLocked}/>
                                ))}
                            </div>
                        </div>
                    </>
                }

                {lessons.extra.length !== 0 &&
                    <>
                        <hr />
                        <div className={cn(styles.sortContainer, {
                            [styles.colSpan]: lessons.basic.length === 0
                        })}>
                            <h6>{t("lesson.extra")} </h6>
                            <div className={styles.buttonContainer}>
                                {lessons.extra.map(item => (
                                    <MenuButton key={item.id} title={item.name} link={`/lesson/${item.id}`} progress={item.completionPercentage} icon={item.completionPercentage === 100 ? "quiz" : "quiz"} disabled={item.isLocked} />
                                ))}
                            </div>

                        </div>
                    </>
                }
            </div>

            {showCongratulationModal?.show && (showCongratulationModal.passed !== undefined ? showCongratulationModal.passed : true) &&
                <NotificationModal content={t(`lessonCompletedMessage.${LESSON_GROUP_TYPE[showCongratulationModal.type]}.part1`, { firstName: showCongratulationModal.firstName, lessonName: group.name })} title={t(`lessonCompletedMessage.${LESSON_GROUP_TYPE[showCongratulationModal.type]}.part2`)} show={true} />
            }

        </>
    );
};

export default Group;