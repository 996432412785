import cn from "classnames";
import styles from "./imageContainer.module.scss";
import { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

interface ImageContainerProps {
    src?: string,
    alt?: string,
    answerChoise?: boolean,
    questionImage?: boolean;
    zoom?: boolean;
}

const ImageContainer: React.FC<ImageContainerProps> = ({ src, alt, answerChoise, questionImage, zoom }) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <>
            <div className={cn(styles.imageContainer, {
                [styles.answerChoise]: answerChoise,
                [styles.questionImage]: questionImage
            })}>
                <img src={src} alt={alt} width="100%" height="100%" onClick={() => setOpen(true)} />
            </div>

            {(zoom && src) &&
                <Lightbox
                    styles={{ root: { "--yarl__color_button_disabled": "transparent" } }}
                    open={open}
                    close={() => setOpen(false)}
                    slides={[
                        { src: src }
                    ]}
                    carousel={
                        { finite: true }
                    }
                />
            }
        </>
    );
};

export default ImageContainer;