import { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./mainMenu.module.scss";
import { changeLanguageFunction } from "@/Interfaces";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import DialogModal from "../modal/DialogModal";
import Cookies from "js-cookie";
import usePortalConfiguration from "@/hooks/usePortalConfiguration";
import useInfoDialog from "@/hooks/useInfoDialog";

interface MainMenuProps {
    supportedlanguages: string[] | null;
    refProp: React.RefObject<HTMLDivElement>;
    locale: changeLanguageFunction;
    lang?: string;
}

const MainMenu: React.FC<MainMenuProps> = ({ supportedlanguages, refProp, locale, lang }) => {
    const [show, setShow] = useState<boolean>(false);
    const { t } = useTranslation();
    const returnToFrontPageUrl = import.meta.env.VITE_WEBAUTO_LOGIN;
    let location = useLocation();
    const courseId = sessionStorage.getItem('COURSE_ID');
    const drivingLicense = sessionStorage.getItem("DRIVING_LICENSE");
    const getPortalConfig = usePortalConfiguration();
    const config = getPortalConfig();
    const getInfoDialod = useInfoDialog();

    useEffect(() => {

        const handleOutSideClick = (event: { target: any; }) => {
            if (refProp.current && !refProp.current?.contains(event.target)) {
                setShow(false);
            }
        };

        if (!show) {
            window.removeEventListener("mousedown", handleOutSideClick);
        } else {
            window.addEventListener("mousedown", handleOutSideClick);
        }


        return () => {
            window.removeEventListener("mousedown", handleOutSideClick);
        };

    }, [show]);

    return (
        <>
            <aside>
                <div className={cn(styles.mainMenu, "offcanvas offcanvas-end")} tabIndex={-1} id="mainMenu" aria-labelledby="mainMenu">
                    <div className={styles.container}>
                        <button type="button" data-bs-toggle="offcanvas" data-bs-target="#mainMenu" aria-controls="mainMenu">
                            <span className="material-symbols-rounded q_white">
                                chevron_right
                            </span>
                        </button>
                    </div>
                    <div className={cn(styles.offcanvasBody, "offcanvas-body")}>
                        <div className={styles.menuTools}>
                            {config?.configuration.areIntroductionTextsEnabled && ['portal', 'lesson'].some(str => location.pathname.includes(str)) &&
                                <DialogModal label={t('info')} title={t('infoDialogTitle')} parts={getInfoDialod()} button closeOnOutSideClick/>
                            }

                            {supportedlanguages &&
                                <div ref={refProp} className={cn(styles.dropdown, {
                                    [styles.disabled]: supportedlanguages.length <= 1
                                })}>
                                    <button className={styles.dropdownToggle} type="button" onClick={() => setShow(!show)}>
                                        <img src={`/assets/flags/${lang}.png`} alt={lang} />
                                    </button>
                                    {(show && supportedlanguages.length > 1) &&
                                        <div className={styles.dropdownMenu}>
                                            {supportedlanguages.map(item => {
                                                return item !== lang &&
                                                    <button key={item} onClick={() => locale(item)} className={styles.dropdownItem}>
                                                        <img src={`/assets/flags/${item}.png`} alt={item} />
                                                    </button>;
                                            })}
                                        </div>
                                    }
                                </div>}
                        </div>
                        <div className={styles.offcanvasDivider}></div>
                        <a href={Cookies.get('returnToFrontPageUrl') ?? returnToFrontPageUrl}>
                            <span data-bs-toggle="offcanvas" data-bs-target="#mainMenu" aria-controls="mainMenu">
                                <span className={cn(styles.q_white, "material-symbols-rounded")}>
                                    home
                                </span>
                                {t('frontPage')}
                            </span>
                        </a>
                        {config?.configuration.isTrophyShopEnabled && <Link to={`/portal/${courseId}/${drivingLicense}/trophies`}>
                            <span data-bs-toggle="offcanvas" data-bs-target="#mainMenu" aria-controls="mainMenu">
                                <span className={cn(styles.q_white, "material-symbols-rounded")}>
                                    trophy
                                </span>
                                {t('trophies')}

                            </span>
                        </Link>}
                        {/* <div className={styles.offcanvasDivider}></div>

                    <Link className={styles.logout} to={Cookies.get('returnUrl') ?? '#'}>
                        <span>
                            <span className="material-symbols-rounded">
                                logout
                            </span>
                            {t('logout')}
                        </span>
                    </Link> */}
                    </div>
                </div>
            </aside>
        </>
    );
};

export default MainMenu;