import { useEffect, useRef, useState } from "react";
import styles from "./modal.module.scss";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import Markdown from "marked-react";

interface DialogModalProps {
    title: string;
    parts: string[];
    label: string;
    button?: boolean;
    autoShow?: boolean;
    closeOnOutSideClick?: boolean;
    closeModalCallBack?: () => void;
}

const DialogModal: React.FC<DialogModalProps> = ({ title, parts, label, button, autoShow, closeOnOutSideClick, closeModalCallBack }) => {
    const [show, setShow] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const ref = useRef(null) as React.RefObject<any>;
    const { t } = useTranslation();

    useEffect(() => {
        if (autoShow) setShow(!show);
    }, []);

    useEffect(() => {
        const handleOutSideClick = (event: { target: any; }) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                handleClose();
            }
        };

        if (closeOnOutSideClick) {
            if (!show) {
                window.removeEventListener("mousedown", handleOutSideClick);
            } else {
                window.addEventListener("mousedown", handleOutSideClick);
            }
        }

        return () => {
            window.removeEventListener("mousedown", handleOutSideClick);
            setCurrentIndex(0);
        };
    }, [show]);

    const handleClose = () => {
        setShow(false);
        if (closeModalCallBack) closeModalCallBack();
    };

    const next = () => {

        if (currentIndex < (parts.length - 1)) {
            setCurrentIndex(currentIndex + 1);
        } else {
            handleClose();
        }
    };

    const previous = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <>
            {button &&
                <a type="button" onClick={() => setShow(!show)}>
                    <span>
                        <span className="material-symbols-rounded">
                            help
                        </span>
                        {label}
                    </span>
                </a>
            }
            {show &&
                <div className={styles.backdrop}>
                    <div ref={ref} className={cn(styles.modal, styles.dialog)}>
                        <div className={styles.header}>
                            <button onClick={handleClose}>
                                <span className="material-symbols-rounded">close</span>
                            </button>
                        </div>
                        <div className={styles.body}>
                            <h6>
                                {title}
                            </h6>

                            <div>
                                <Markdown>{parts[currentIndex]}</Markdown>
                            </div>
                            <div className={styles.hemmo}>
                                <img src="/assets/hemmo/hemmo_1.svg" alt="" height={100} width={100} />
                            </div>

                            <div className={styles.footer}>
                                {currentIndex > 0 && <button onClick={previous} className={styles.previous}>{t("previous")}</button>}

                                <button onClick={next} className={styles.next}>{currentIndex === (parts.length - 1) ? t("close") : t("next")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};


export default DialogModal;