import { useState } from "react";
import styles from "./zoomButton.module.scss";
import cn from "classnames";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


interface ZoomButtonProps {
    urls: string[];
    large?: boolean,
    backgroundBlue?: boolean;
    backgroundDarkBlue?: boolean;
}

const ZoomButton: React.FC<ZoomButtonProps> = ({ urls, large, backgroundBlue, backgroundDarkBlue }) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <div className={styles.zoomButton}>
                <button type="button" aria-label="Read" className={cn({
                    [styles.large]: large,
                    [styles.backgroundBlue]: backgroundBlue,
                    [styles.backgroundDarkBlue]: backgroundDarkBlue,
                })} onClick={() => setOpen(true)}>
                    <span className="material-symbols-rounded">
                        zoom_in
                    </span>
                </button>
            </div>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={
                    urls.map((url) => {
                        return { src: url };
                    })
                }
            />
        </>
    );
};

export default ZoomButton;