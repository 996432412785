import { useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from "./error.module.scss";
import Cookies from 'js-cookie';

export default function ErrorPage(): JSX.Element {
    const { t } = useTranslation();
    const error = useRouteError() as Error;
    const returnToFrontPageUrl = import.meta.env.VITE_WEBAUTO_LOGIN;
    console.error(error);
    
    // useEffect(() => {
    //     captureException(error, { level: 'error' })
    // }, [error])

    return (
        <div className={styles.container}>
            <div id="error-page">
                <h1>{t('oops')}</h1>
                <p>{t('errorOccured')}</p>
                <p>
                    <i>{error.message || (error as { statusText?: string; })?.statusText}</i>
                </p>
            </div>
            <div className={styles.footer}>
                <a href={Cookies.get('returnToFrontPageUrl') ?? returnToFrontPageUrl}>{t('goBack')}</a>
            </div>
        </div>
    );
};
