import fi from './translations/fi.json';
import en from './translations/en.json';
import sv from './translations/sv.json';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const DEFAULT_LANGUAGE = "fi";
const LOCALE_STORAGE_KEY = 'SELECTED-LOCALE';

// i18n does not keep track of available languages described under resources, we have to manually keep track of which selections we have in the app.
export const availableLanguages = [
    { key: "en", label: "English", jsonTranslations: en },
    { key: "fi", label: "Suomi", jsonTranslations: fi },
    { key: "sv", label: "Svenska", jsonTranslations: sv },
];

const resources = {};
availableLanguages.forEach((language) => {
    resources[language.key] = { translation: language.jsonTranslations };
});

i18n
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',
        resources,
        lng: DEFAULT_LANGUAGE,
        fallbackLng: DEFAULT_LANGUAGE,
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });


const setLocale = async (locale) => {
    await i18n.changeLanguage(locale);

    try {
        return localStorage.setItem(LOCALE_STORAGE_KEY, locale);
    } catch (error) {
        console.error(error);
    }
};

const getLocale = () => {
    return localStorage.getItem(LOCALE_STORAGE_KEY) ?? DEFAULT_LANGUAGE;
};

const locale = getLocale();

if (!locale) {
    setLocale(DEFAULT_LANGUAGE);
} else {
    setLocale(locale);
}


export { i18n as localizations, DEFAULT_LANGUAGE, setLocale, getLocale };
