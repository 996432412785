import { useCallback } from "react";

/**
 * 
 * @param props property to define when to trigger scroll action
 * @returns void
 */
const useScrollOnRender = (props: unknown) => {
    const ref = useCallback((node: HTMLElement | null) => {
        node?.scrollIntoView({ behavior: 'smooth' });
    }, [props]);

    return ref;
};

export default useScrollOnRender;