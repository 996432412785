import { useEffect, useRef, useState } from "react";
import styles from "./readerButton.module.scss";
import cn from "classnames";
import { textToSpeech } from "@/utils/waApiClient";
import { getLocale } from "@/locales/locales";
import { useAudio } from "../audioContext/AudioContext";


interface ReaderButtonProps {
    text: string,
    large?: boolean,
    backgroundBlue?: boolean;
    backgroundDarkBlue?: boolean;
}

const ReaderButton: React.FC<ReaderButtonProps> = ({ text, large, backgroundBlue, backgroundDarkBlue }) => {
    const [reading, setReading] = useState<boolean>(false);
    const { playAudio, pauseAudio, currentAudio } = useAudio();
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        const audioElement = audioRef.current;
        
        if (audioElement) {
            if (currentAudio !== audioElement) {
                setReading(false);
                }

            const handleEnded = () => {
                if (currentAudio === audioElement) {
                    setReading(false);
                }
            };

            audioElement.addEventListener('ended', handleEnded);

            return () => {
                pauseAudio(audioElement);
                audioElement.removeEventListener('ended', handleEnded);
            };
        }
    }, [currentAudio]);

    const handlePlay = () => {
        (async () => {
            try {
                const form = new FormData();

                form.append("text", text);
                form.append("provider", 'aimater');
                form.append("language", getLocale());
                form.append("audioEncoding", "MP3");

                setReading(true);
                await textToSpeech({ form })
                    .then(response => {
                        if (response.status !== "success") throw new Error();
                        const audio = new Audio(`data:audio/${response.data.audioEncoding};base64,${response.data.audio}`);
                        audioRef.current = audio;

                        playAudio(audio);
                    }
                    );
            }
            catch (error) {
                console.error(error);
            }
        })();
    };

    return (
        <div className={styles.readerButton}>
            <button type="button" aria-label="Read" disabled={reading} className={cn({
                [styles.reading]: reading,
                [styles.large]: large,
                [styles.backgroundBlue]: backgroundBlue,
                [styles.backgroundDarkBlue]: backgroundDarkBlue,
            })} onClick={handlePlay}>
                <span className="material-symbols-rounded">
                    volume_up
                </span>
            </button>
        </div>
    );
};

export default ReaderButton;