import { useTranslation } from "react-i18next";
import styles from "./completed.module.scss";
import { LessonCompletionSummary } from "@/Interfaces";
import { Link, LoaderFunctionArgs, Params, useLoaderData } from "react-router-dom";
import Markdown from "marked-react";
import { getLessonSummary } from "@/utils/waApiClient";
import SummaryDropdown from "@/components/summaryDropdown/SummaryDropdown";
import { useEffect, useState } from "react";
interface Args extends LoaderFunctionArgs {
    params: Params<string>;
}

export const lessonCompletedLoader = async ({ params }: Args) => {
    const { completionId } = params;

    if (!completionId) throw new Error();
    const response = await getLessonSummary({ completionId });
    return response.data;
};

const Completed = () => {
    const [lessonFeedback, setLessonFeedback] = useState('');
    const { t } = useTranslation();
    const lessonCompletionSummary = useLoaderData() as LessonCompletionSummary;
    const courseId = sessionStorage.getItem('COURSE_ID')
    const drivingLicense = sessionStorage.getItem("DRIVING_LICENSE")
    const courseGroupId = sessionStorage.getItem('COURSE_GROUP_ID')

    useEffect(() => {
        if (lessonCompletionSummary.completionPercentage === 100) { setLessonFeedback(t('sectionFeedback.perfectCompletion')); }
        if (lessonCompletionSummary.completionPercentage <= 99) { setLessonFeedback(t('sectionFeedback.wowAmazing')); }
        if (lessonCompletionSummary.completionPercentage <= 90) { setLessonFeedback(t('sectionFeedback.wellDone')); }
        if (lessonCompletionSummary.completionPercentage <= 70) { setLessonFeedback(t('sectionFeedback.decentCompletion')); }
        if (lessonCompletionSummary.completionPercentage <= 50) { setLessonFeedback(t('sectionFeedback.oops')); }
        if (lessonCompletionSummary.completionPercentage <= 10) { setLessonFeedback(t('sectionFeedback.morePractice')); }

        if (lessonCompletionSummary.levelExerciseType === 3) {
            const showCongratulationModal = sessionStorage.getItem('SHOW_CONGRATULATION_MODAL');
            if (showCongratulationModal) {
                const temp = JSON.parse(showCongratulationModal);
                temp.passed = lessonCompletionSummary.passed;
                sessionStorage.setItem('SHOW_CONGRATULATION_MODAL', JSON.stringify(temp));
            }
        }

    }, []);


    return (
        lessonCompletionSummary &&
        <div className={styles.feedbackContainer}>
            <div className={styles.contentWrapper}>
                <div className={styles.header}>
                    <h4>{t('sectionCompleted')}</h4>
                </div>
                <div className={styles.body}>
                    {lessonCompletionSummary.collectedRubies || lessonCompletionSummary.collectedDiamonds &&

                        <div className={styles.diamondCount}>
                            <h4>+{lessonCompletionSummary.collectedRubies}</h4>
                            <div className={styles.bgCirlce}>
                                <img src="/assets/trophies/ruby.svg" alt="" width="24px" height="24px" />
                                {/* <img src=/"src/assets/trophies/diamond.svg" alt="" width="30px" height="24px"/> */}
                            </div>
                        </div>
                    }
                    <div>
                        <Markdown>{t('sectionFeedback.exericesCompleted', { sectionName: lessonCompletionSummary.lessonName })}</Markdown>
                    </div>
                    <div className={styles.lessonSummary}>
                        {lessonCompletionSummary.passed !== null && <h2 className={lessonCompletionSummary.passed ? styles.passed : styles.failed}>{lessonCompletionSummary.passed ? t('passed') : t('failed')}</h2>}
                        <p>{t('correctAnswers')}:</p>
                        <h1>{lessonCompletionSummary.correctAnswers}/{lessonCompletionSummary.questionCount}</h1>
                        <b>{lessonFeedback}</b>
                    </div>
                    <Link to={`/portal/${courseId}/${drivingLicense}/group/${courseGroupId}`} className={styles.backToPortal}>{t("backToPortal")}</Link>
                </div>
                <div>
                    <SummaryDropdown title={lessonCompletionSummary.levelExerciseType !== 3 ? t('questionSummary') : t('wrondAnswers')} questions={lessonCompletionSummary.questions} totalCount={lessonCompletionSummary.questionCount} />
                </div>
            </div>
        </div>
    );
};

export default Completed;