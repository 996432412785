import VideoContainer from "@/components/video/videoContainer";
import styles from "@/views/portal/portal.module.scss";
import MenuButton from "@/components/menuButton/MenuButton";
import { Link, useRouteLoaderData } from "react-router-dom";
import { LandingData } from "@/Interfaces";
import { useTranslation } from "react-i18next";
import usePortalConfiguration from "@/hooks/usePortalConfiguration";
import cn from 'classnames';
import Cookies from "js-cookie";

const Landing = () => {
    const { t } = useTranslation();
    const landingData = useRouteLoaderData('portal') as LandingData;
    const getPortalConfig = usePortalConfiguration();
    const config = getPortalConfig();
    const courseId = sessionStorage.getItem('COURSE_ID');
    const drivingLicense = sessionStorage.getItem("DRIVING_LICENSE");
    const returnToFrontPageUrl = import.meta.env.VITE_WEBAUTO_LOGIN;

    return (
        <>
            <header>
            <Link to={Cookies.get('returnToFrontPageUrl') ?? returnToFrontPageUrl} className={styles.return}>
                    <span className={cn(styles.mobile, "material-symbols-rounded q_white")}>
                        chevron_left
                    </span>
                    <div className={cn(styles.desktop)}>
                        <div >
                            <span className="material-symbols-rounded">
                                keyboard_return
                            </span>
                        </div>
                        <span>{t('goBack')}</span>
                    </div>
                </Link>
                <div>
                    <div className={styles.headerBox}>
                        <h4>{t("tasks")}</h4>
                        {config?.configuration.isIntroductionVideoEnabled && <div>
                            <VideoContainer fileLocationType={4} src="https://player.vimeo.com/video/904052145" />
                        </div>}
                    </div>
                </div>
            </header>

            <div className={styles.menuContainer}>
                {landingData.groups.map(item => (
                    <MenuButton key={item.id} title={item.name} border link={`group/${item.id}`} progress={item.completionPercentage} image={item.media.thumbnail.url} />
                ))}
                {config?.configuration.isTrophyShopEnabled && <MenuButton title={t("trophies")} border link={`/portal/${courseId}/${drivingLicense}/trophies`} color="primary-white" backgroundImage="/assets/buttonImages/button_background_1.png" image="/assets/thumbnails/trophies.svg" />}
            </div>
        </>
    );
};

export default Landing;