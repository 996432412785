import { createRoot } from 'react-dom/client';
import '@/index.css';
import App from '@/App';
import '@/locales/locales';
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import React from "react";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

if (import.meta.env.MODE === "production" || import.meta.env.MODE === "staging") {
    Sentry.init({
        dsn: "https://5c747d2ea5bc1721b8dc2bbb594166bc@o60796.ingest.us.sentry.io/4506416315105280",
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            }),
            Sentry.replayIntegration(),
            Sentry.httpClientIntegration({ failedRequestStatusCodes: [[400, 599]] }),
            Sentry.captureConsoleIntegration({ levels: ['error'] })
        ],
        tracesSampleRate: 1.0,
        environment: import.meta.env.MODE,
        release: APP_VERSION,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        sendDefaultPii: true,
    });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <App />
);