import { getLocale } from '@/locales/locales';
import { captureException } from '@sentry/core';
import Cookies from 'js-cookie';

const WEBAUTO_API_BASE = Cookies.get("apiBaseUrl");

function getHeaders() {
    return {
        "Accept-Language": getLocale(),
    };
}

class WaApiClient {
    async get(url: string) {

        try {
            const response = await fetch(url, {
                method: "GET",
                credentials: "include",
                headers: getHeaders()
            });

            const responseJson = await response.json();

            if (responseJson.status !== "success") throw new Error(responseJson.data.message);
            return responseJson;

        } catch (error) {
            captureException(error);
            throw Error();
        }
    }

    async post(url: string, form?: FormData) {
        try {
            const response = await fetch(url, {
                method: "POST",
                credentials: "include",
                body: form,
                headers: getHeaders()
            });

            const responseJson = await response.json();

            if (responseJson.status !== "success") throw new Error(responseJson.data.message);
            return responseJson;

        } catch (error) {
            captureException(error);
            throw Error();
        }
    }
}

export const startLesson = (params: { form: FormData; }) => {
    const { form } = params;
    
    if (!form) throw new Error;
    return api.post(`${WEBAUTO_API_BASE}/api/v1/self-study/courses/${form.get('courseId')}/lessons/${form.get("lessonId")}/start`, form);
};

export const getLesson = (params: { lessonId: string; courseId: string }) => {
    const { lessonId, courseId } = params;
    return api.get(`${WEBAUTO_API_BASE}/api/v1/self-study/courses/${courseId}/lessons/${lessonId}`);
};

export const getSections = (params: { completionId: string; }) => {
    const { completionId } = params;
    if (!completionId) throw new Error;
    return api.get(`${WEBAUTO_API_BASE}/api/v1/self-study/lesson-progress/${completionId}/sections`);

};

export const getSectionData = (params: { url: string; }) => {
    const { url } = params;
    if (!url) throw new Error;
    return api.get(`${WEBAUTO_API_BASE}${url}`);
};

export const setLessonComplete = (params: { form: FormData; }) => {
    const { form } = params;
    if (!form) throw new Error;

    return api.post(`${WEBAUTO_API_BASE}/api/v1/self-study/lesson-progress/${form.get("completionId")}/complete`, form);
};

export const setSectionComplete = (params: { form: FormData; }) => {
    const { form } = params;
    if (!form) throw new Error;
    return api.post(`${WEBAUTO_API_BASE}/api/v1/self-study/lesson-progress/${form.get("completionId")}/sections/${form.get("sectionNumber")}/complete`, form);

};

export const abortLesson = (params: { form: FormData; }) => {
    const { form } = params;

    if (!form) throw new Error;
    return api.post(`${WEBAUTO_API_BASE}/api/v1/self-study/lesson-progress/${form.get("completionId")}/abort`, form);

};

export const getQuestion = (params: { questionId: string, completionId: string; }) => {
    const { questionId } = params;
    const { completionId } = params;
    if (!questionId || !completionId) throw new Error;

    return api.get(`${WEBAUTO_API_BASE}/api/v1/self-study/lesson-progress/${completionId}/questions/${questionId}`);
};

export const saveAnswer = (params: { form: FormData; }) => {
    const { form } = params;
    if (!form) throw new Error;
    return api.post(`${WEBAUTO_API_BASE}/api/v1/self-study/lesson-progress/${form.get("completionId")}/questions/${form.get("questionId")}/answer`, form);
};

export const getQuestionFormSummary = (params: { questionFormId: string; completionId: string; }) => {
    const { completionId } = params;
    const { questionFormId } = params;
    if (!completionId || !questionFormId) throw new Error;
    return api.get(`${WEBAUTO_API_BASE}/api/v1/self-study/lesson-progress/${completionId}/question-form-summary/${questionFormId}`);
};

export const getBookPart = (params: { pageId: string; bookId: string; completionId: string; }) => {
    const { bookId } = params;
    const { pageId } = params;
    const { completionId } = params;
    if (!bookId || !pageId) throw new Error;
    return api.get(`${WEBAUTO_API_BASE}/api/v1/self-study/lesson-progress/${completionId}/books/${bookId}/pages/${pageId}`);
};

export const getLessonGroups = (params: { courseId: string; drivingLicense: string; }) => {
    const { courseId } = params;
    const { drivingLicense } = params;

    return api.get(`${WEBAUTO_API_BASE}/api/v1/self-study/courses/${courseId}/subject-groups/${drivingLicense}`);
};

export const getGroupData = (params: { id: string; courseId: string; drivingLicense: string; }) => {
    const { id } = params;
    const { courseId } = params;
    const { drivingLicense } = params;

    if (!id) throw new Error;
    return api.get(`${WEBAUTO_API_BASE}/api/v1/self-study/courses/${courseId}/subject-groups/${drivingLicense}/${id}`);
};

export const getLessonSummary = (params: { completionId: string; }) => {
    const { completionId } = params;
    if (!completionId) throw new Error;
    return api.get(`${WEBAUTO_API_BASE}/api/v1/self-study/lesson-progress/${completionId}/completion-summary`);
};

export const textToSpeech = (params: { form: FormData; }) => {
    const { form } = params;
    if (!form) throw new Error;
    return api.post(`${WEBAUTO_API_BASE}/api/v1/text-to-speech`, form);
};

export const getMaterialBook = (params: { bookId: string; }) => {
    const { bookId } = params;
    if (!bookId) throw new Error;
    return api.get(`${WEBAUTO_API_BASE}/api/v1/self-study/books/${bookId}`);
};

export const getMaterialBookPage = (params: { pageId: string; bookId: string; }) => {
    const { bookId } = params;
    const { pageId } = params;
    if (!bookId || !pageId) throw new Error;
    return api.get(`${WEBAUTO_API_BASE}/api/v1/self-study/books/${bookId}/pages/${pageId}`);
};

export const getPortalConfiguration = (params: { courseId: string; drivingLicense: string; }) => {
    const { courseId } = params;
    const { drivingLicense } = params;

    return api.get(`${WEBAUTO_API_BASE}/api/v1/self-study/courses/${courseId}/portal-configuration/${drivingLicense}`);
};

const api = new WaApiClient();
