import styles from "./lessonRoot.module.scss";
import { LoaderFunctionArgs, Outlet, ParamParseKey, Params, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";
import { LessonData } from "@/Interfaces";
import { useEffect } from "react";
import { getLesson } from "@/utils/waApiClient";

type ContextType = { lang: string[], setSupportedLanguages: React.Dispatch<React.SetStateAction<string[]>>; };

const PathNames = {
    id: '/:id',
} as const;

interface Args extends LoaderFunctionArgs {
    params: Params<ParamParseKey<typeof PathNames.id>>;
}


export const lessonRootLoader = async ({ params }: Args): Promise<LessonData> => {
    const { id } = params;
    const lessonId = id ? id : sessionStorage.getItem("LESSON_ID");
    const courseId = sessionStorage.getItem("COURSE_ID");
    
    if (!lessonId || !courseId) throw new Error();
    
    const response = await getLesson({ lessonId, courseId });
    
	sessionStorage.setItem("LESSON_ID", lessonId);
    return response.data;
};


const LessonRoot = () => {
    const lessonData = useLoaderData() as LessonData;
    const { lang } = useOutletContext<ContextType>();
    const { setSupportedLanguages } = useOutletContext<ContextType>();
    const navigate = useNavigate();

    useEffect(() => {
        setSupportedLanguages(lessonData.supportedLanguages);
    }, []);

    return (
        <>
            <div className={styles.container}>
                <a type="button" onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                }}>
                    <span className="material-symbols-rounded q_white">
                        chevron_left
                    </span>
                </a>
                <div>
                    {<b>{lessonData.name}</b>}
                </div>
                <div>

                </div>
            </div>
            <Outlet context={lang} />
        </>
    );
};
export default LessonRoot;