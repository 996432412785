import styles from "./media.module.scss";
import { CurrentSection, Navigation } from "@/Interfaces";
import ImageContainer from "@/components/image/ImageContainer";
import VideoContainer from "@/components/video/videoContainer";
import { useEffect } from "react";

interface MediaProps {
    currentSection: CurrentSection,
    navigation: Navigation;
    setCanContinue: React.Dispatch<React.SetStateAction<boolean>>,
}

const Media: React.FC<MediaProps> = ({ currentSection, navigation, setCanContinue }) => {

    useEffect(() => {
      setCanContinue(true);
    }, [])
    const Isimage = (): boolean => {
        if (!currentSection.sectionData || !currentSection.sectionData.fileUrl) return false;
        return (/\.(jpe?g|png|svg)$/i).test(currentSection.sectionData.fileUrl);
    };

    return (
        currentSection.sectionData &&
        <>
            <div className={styles.title}>
                <h6>{currentSection.sectionData.name}</h6>
            </div>
            {Isimage() ?
                <ImageContainer src={currentSection.sectionData.fileUrl} alt={currentSection.sectionData.name}/>
                :
                <VideoContainer src={currentSection.sectionData.fileUrl} fileLocationType={currentSection.sectionData.fileLocationType ?? 0}/>
            }
        </>

    );
};

export default Media;