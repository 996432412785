import styles from "./book.module.scss";
import cn from "classnames";
import { BookPart, BookPopup, togglePopupFunction } from "@/Interfaces";
import { useEffect, useState } from "react";
import PopOver from "./PopOver";
import PopUp from "./PopUp";
import Paragraphs from "./Paragraphs";
import Dialog from "./Dialog";
import ReaderButton from "@/components/readerButton/ReaderButton";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

interface BookPartProps {
    part: BookPart;
}

const ICON_LIST: { [key: string]: string; } = {
    "1": "lightbulb",
    "2": "settings_alert",
    "3": "book_5",
    "4": "school",
    "5": "add",
    "6": "close"
};

const Part: React.FC<BookPartProps> = ({ part }) => {
    const [popup, setPopup] = useState<BookPopup | null>(null);
    const [popover, setPopover] = useState<BookPopup | null>(null);
    const [popupIndex, setPopupIndex] = useState<number | null>(null);
    const [dialogRef, setDialogRef] = useState(null);
    const [lightboxOpen, setLightboxOpen] = useState<null | string>(null);

    useEffect(() => {
        return () => {
            setPopover(null);
            setPopup(null);
            setPopupIndex(null);
        };
    }, [part]);

    useEffect(() => {
        const images = document.querySelectorAll('#form-container p>img') as NodeListOf<HTMLImageElement>;

        images.forEach(image => {
            image.addEventListener('click', () => setLightboxOpen(image.src));
        });

    }, [popup, popover, dialogRef]);


    const togglePopup: togglePopupFunction = (params) => {
        const { item } = params;
        const { index } = params;

        setPopover(null);
        setPopup(null);
        setPopupIndex(null);

        if (index === null || index === popupIndex) return;

        if (item.type === 1) {
            setPopover(item);
            setPopupIndex(index);
        }

        if (item.type === 2) {
            setPopup(item);
            setPopupIndex(index);
        }


    };

    return (
        part &&
        <>
            <div className={cn(styles.background, (popover && styles.popover))} style={{
                backgroundImage: `url(${part.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}>
                {!popover ?
                    <>
                        <Paragraphs part={part} />
                        {part.dialogs && <Dialog setDialogRef={setDialogRef} dialogs={part.dialogs} part={part} iconList={ICON_LIST} />}
                    </>
                    :
                    <PopOver popover={popover} togglePopup={togglePopup} />
                }
                {popup && <PopUp popup={popup} />}
            </div>
            <div className={styles.buttonBar}>
                <div className={styles.buttonContainer}>
                    {part.popups?.map((item, index) => (
                        index % 2 === 0 &&
                        <button onClick={() => togglePopup({ item, index })} key={index} className={cn(styles.circle, styles.small)}>
                            <span className="material-symbols-rounded q_white">
                                {ICON_LIST[item.icon]}
                            </span>
                        </button>
                    ))}
                </div>
                <ReaderButton text={part.paragraphs.map(item => (item.text)).toString()} large backgroundBlue />
                <div className={styles.buttonContainer}>
                    {part.popups?.map((item, index) => (
                        index % 2 === 1 &&
                        <div onClick={() => togglePopup({ item, index })} key={index} className={cn(styles.circle, styles.small)}>
                            <span className="material-symbols-rounded q_white">
                                {ICON_LIST[item.icon]}
                            </span>
                        </div>
                    ))}
                </div>
            </div>

            <Lightbox
                styles={{ root: { "--yarl__color_button_disabled": "transparent" } }}
                open={!!lightboxOpen}
                close={() => setLightboxOpen(null)}
                slides={[
                    { src: !lightboxOpen ? '' : lightboxOpen }
                ]}
                carousel={
                    { finite: true }
                }
            />
        </>
    );
};

export default Part;