import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const useInfoDialog = () => {
    const { t } = useTranslation();
    const infoDialod = useCallback((): string[] => {
        let index = 1;
        let infoDialog = [];

        while (true) {
            if (t(`infoDialog.${index}`) === `infoDialog.${index}`) break;

            infoDialog.push(t(`infoDialog.${index}`));
            index++;
        }

        return infoDialog;
    }, []);

    return infoDialod;
};

export default useInfoDialog;