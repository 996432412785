import { PortalConfiguration } from "@/Interfaces";
import { useCallback } from "react";

const usePortalConfiguration = () => {
    const config = useCallback((): PortalConfiguration | null => {
        const temp = sessionStorage.getItem('PORTAL_CONFIG');
        try {
            return temp ? JSON.parse(temp) : null;
        } catch (error) {
            console.error('Failed to parse portal configuration:', error);
            return null;
        }
    }, []);

    return config;
};

export default usePortalConfiguration;