import cn from "classnames";
import styles from "./videoContainer.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import VimeoPlayer from "@vimeo/player";
import VimeoUtils from "@/utils/vimeo-utils";

interface VideoContainerProps {
    fileLocationType: number,
    src?: string,
    alt?: string;
}

const VideoContainer: React.FC<VideoContainerProps> = ({ src, alt, fileLocationType }) => {
    const [videoId, setVideoId] = useState<string | null>(null);

    const playerRef = useCallback((node: HTMLIFrameElement) => {
        if (isVimeoLink() && videoId && node) {
            const player = new VimeoPlayer(node);

            // Fetch video progress from localStorage and update the player currentTime
            const videoProgress = VimeoUtils.getVideoProgress(parseInt(videoId));
            player.setCurrentTime(videoProgress);

            // Attach the timeupdate event to update progress in localStorage
            player.on('timeupdate', updateTimeProgress);

            // Clear localStorage entry once video ends
            player.on('ended', clearVideoProgress);

            // Delete old entries
            VimeoUtils.removeAllVideoProgress();
        }
    }, [videoId]);

    const updateTimeProgress = useCallback((event: { seconds: number; }) => {
        videoId && VimeoUtils.setVideoProgress(parseInt(videoId), event.seconds);
    }, [videoId]);

    const clearVideoProgress = useCallback(() => {
        videoId && VimeoUtils.removeVideoProgress(parseInt(videoId));
    }, [videoId]);   


    useEffect(() => {
        if (src && isVimeoLink()) {
            setVideoId(VimeoUtils.getVideoIdFromURL(src));
        }
    }, [src]);

    const isVimeoLink = (): boolean => {
        return fileLocationType === 4;
    };

    return (
        <div className={cn(styles.iframeContainer)}>
            <iframe ref={playerRef} src={src} id="videoPlayer" allowFullScreen></iframe>
        </div>
    );
};

export default VideoContainer;