import { LessonData } from "@/Interfaces";
import { useTranslation } from "react-i18next";
import { Form, Link } from "react-router-dom";
import styles from "./startLessonForms.module.scss";
import Markdown from "marked-react";

interface StartExamFormProps {
    lessonData: LessonData;
}

const StartExamForm: React.FC<StartExamFormProps> = ({ lessonData }) => {
    const { t } = useTranslation();
    const courseId = sessionStorage.getItem('COURSE_ID') || undefined;
    const drivingLicense = sessionStorage.getItem("DRIVING_LICENSE");
    const courseGroupId = sessionStorage.getItem('COURSE_GROUP_ID');

    return (
        <div className={styles.instructionContainer}>
            <div className={styles.contentWrapper}>
                <div>
                    <h6>{t('examInstructions.welcome')}</h6>
                </div>
                <div>
                    <Markdown>{t('examInstructions.instruction', {
                        lessonName: lessonData.name,
                        nextButton: '![Next question](/assets/buttonImages/q_button_forward-right_24.png "Next question")',
                        abortButton: '![Abort lesson](/assets/buttonImages/q_button_back-left_24.png "Abort lesson")',
                        correctAnswers: lessonData.correctAnswerThreshold,
                        questionCount: lessonData.questionCount
                    })}</Markdown>
                </div>
                <Form className={styles.form} method="post" id="startOnlineLessonForm">
                    <input type="text" name="lessonId" value={lessonData.id} hidden readOnly />
                    <input type="text" name="courseId" value={courseId} hidden readOnly />
                    <div className={styles.footer}>
                        <Link to={`/portal/${courseId}/${drivingLicense}/group/${courseGroupId}`} className={styles.backToPortal}>{t("backToPortal")}</Link>
                        <button type="submit">{t('examInstructions.start')}</button>
                    </div>
                </Form>
            </div>
        </div >
    );
}; 

export default StartExamForm;