import { useEffect, useRef } from "react";
import styles from "./modal.module.scss";
import VideoContainer from "../video/videoContainer";

interface VideoModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    videoUrl: string
}

const VideoModal: React.FC<VideoModalProps> = ({ show, setShow, videoUrl }) => {
    const ref = useRef(null) as React.RefObject<any>;

    useEffect(() => {
        
        const handleOutSideClick = (event: { target: any; }) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                setShow(false);
            }
        };

        if (!show) {
            window.removeEventListener("mousedown", handleOutSideClick);
        } else {
            window.addEventListener("mousedown", handleOutSideClick);
        }

        return () => {
            window.removeEventListener("mousedown", handleOutSideClick);
        };

    }, [show]);
    return (
        show &&
        <div className={styles.backdrop}>
            <div ref={ref} className={styles.modal}>
                <div className={styles.header}>
                    <span>Esittelyvideo</span>
                    <button onClick={() => setShow(false)}>
                        <span className="material-symbols-rounded">close</span>
                    </button>
                </div>
                <VideoContainer fileLocationType={4} src={videoUrl} />
            </div>
        </div>
    );
};


export default VideoModal;