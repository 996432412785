import { redirect, useRouteLoaderData, useSubmit } from 'react-router-dom';
import { LessonData } from '@/Interfaces';
import { useEffect } from 'react';
import StartExamForm from '@/components/startLessonForms/StartExamForm';
import styles from "./start.module.scss";
import { startLesson } from '@/utils/waApiClient';

interface StartForms {
	[key: number]: JSX.Element;
};

const Start = () => {
	const lessonData = useRouteLoaderData("lesson") as LessonData;
	const submit = useSubmit();

	const START_LESSON_FORMS: StartForms = {
		3: <StartExamForm lessonData={lessonData} />
	};

	// Check the lesson type and whether to renred a starting form. Default behaviour is to start the lesson automatically
	useEffect(() => {

		if (lessonData.levelExerciseType !== 3) {
			
			submit(
				{
					lessonId: lessonData.id,
					courseId: sessionStorage.getItem("COURSE_ID")
				},
				{
					method: "post",
					encType: "application/x-www-form-urlencoded",
					replace: true
				}
			);
		}

	}, [lessonData]);

	return (
		<div className={styles.mainContainer}>
			{lessonData.levelExerciseType && START_LESSON_FORMS[lessonData.levelExerciseType]}
		</div>
	);
};

export const startLessonAction = async ({ request }: { request: Request; }) => {
	const form = await request.formData();
	const response = await startLesson({ form });
	
	sessionStorage.setItem("COMPLETION_ID", response.data.completionId);

	if (response.data.isLessonCompleted) {
		return redirect(`/lesson/${response.data.completionId}/completed`);
	}

	return redirect(`/lesson/${response.data.completionId}/play`);
};

export default Start;