import styles from "./progressBar.module.scss";

interface ProgressBarProps {
    progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {

    return (
        <div className={styles.container}>
            <div className={styles.progressBarContainer}>
                <div className={styles.progress} >
                    <div className={styles.progressBar} style={{ width: `${progress}%` }} role="progressbar"></div>
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
