import { useState } from "react";
import styles from "./modal.module.scss";
import cn from "classnames";
import VideoModal from "./VideoModal";

interface ModalButtonProps {
    title: string;
    subTitle?: string;
    icon?: string;
    image?: string;
    border?: boolean;
    disabled?: boolean;
    type: string;
    videoUrl?: string;
}

const ModalButton: React.FC<ModalButtonProps> = ({ title, subTitle, icon, image, border, disabled, type, videoUrl }) => {
    const [show, setShow] = useState<boolean>(false);

    return (
        <>
            <button className={cn(styles.button, {
                [styles.border]: border,
                [styles.disabled]: disabled
            })} onClick={() => setShow(!show)}>
                <div className={styles.content}>
                    {icon
                        ? <span className={styles.iconBackground}>
                            <span id="q_icon" className="material-symbols-rounded q_white">{icon}</span>
                        </span>
                        : image &&
                        <div className={styles.imageContainer}>
                            <img src={image} alt="" width="48px" height="48px" />
                        </div>
                    }
                    <div>
                        <b>{title}</b>
                        <br />
                        {subTitle && subTitle}
                    </div>
                </div>
            </button>
            {(type === "video" && videoUrl) &&
                <VideoModal show={show} setShow={setShow} videoUrl={videoUrl} />
            }

        </>
    );
};

export default ModalButton;