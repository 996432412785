const VIMEO_PROGRESS_PREFIX = 'vimeo-progress-';

/**
 * Vimeo Utilities for DD.
 */
export default class VimeoUtils {


	/**
	 * Stores video progress object into sessionStorage.
	 * 
	 * @param {number} videoId Vimeo video ID.
	 * @param {number} progress Progress in seconds.
	 * @return {void}
	 */
	static setVideoProgress = (videoId, progress) => {
		let key = VIMEO_PROGRESS_PREFIX + videoId;
		let value = {
			"progress": progress,
			"createdAtMilliseconds": Date.now()
		}
		return sessionStorage.setItem(key, JSON.stringify(value));
	}


	/**
	 * Returns video progress object from sessionStorage.
	 * 
	 * @param {number} videoId Vimeo video ID.
	 * @return {Object|null} JSON Object or null.
	 */
	static getVideoProgressObject = (videoId) => {
		let key = VIMEO_PROGRESS_PREFIX + videoId;
		let videoProgress = sessionStorage.getItem(key);

		if (!videoProgress) {
			return null;
		}

		return JSON.parse(videoProgress);
	}


	/**
	 * Returns video progress.
	 * 
	 * @param {number} videoId Vimeo video ID
	 * @return {number} Video progress in seconds.
	 */
	static getVideoProgress = (videoId) => {
		let videoProgressObject = this.getVideoProgressObject(videoId);

		if (!videoProgressObject) {
			return 0;
		}

		return videoProgressObject.progress;
	}


	/**
	 * Returns the timestamp when video progress object was created or updated.
	 * 
	 * @param {number} videoId Vimeo video ID.
	 * @return {number} Timestamp, in milliseconds.
	 */
	static getVideoProgressCreatedAt = (videoId) => {
		let videoProgressObject = this.getVideoProgressObject(videoId);

		if (!videoProgressObject) {
			return Date.now();
		}

		return videoProgressObject.createdAtMilliseconds;
	}


	/**
	 * Removes video progress object from sessionStorage.
	 * 
	 * @param {number} videoId Vimeo video ID.
	 * @return {void}
	 */
	static removeVideoProgress = (videoId) => {
		let key = VIMEO_PROGRESS_PREFIX + videoId;
		return sessionStorage.removeItem(key);
	}


	/**
	 * Returns Vimeo video ID from URL, e.g. `https://player.vimeo.com/video/76979871` returns `76979871`.
	 * 
	 * @param {string} videoURL Vimeo video URL.
	 * @return {string|null} Vimeo video ID.
	 */
	static getVideoIdFromURL = (videoURL) => {
		let match = videoURL.match(/^.+vimeo.com\/(.*\/)?([^#\?]*)/);
		return match ? match[2] || match[1] : null;
	}


	/**
	 * Removes old video progress objects from sessionStorage.
	 * 
	 * @param {number} hoursThreshold Threshold for when object is considered old, defaults to `24`.
	 */
	static removeAllVideoProgress = (hoursThreshold = 24) => {
		const dateNowMilliseconds = Date.now();

		for (let key in sessionStorage) {
			if (!key.startsWith(VIMEO_PROGRESS_PREFIX)) {
				continue;
			}

			let videoId = key.split(VIMEO_PROGRESS_PREFIX).pop();
			let videoProgressCreatedAt = this.getVideoProgressCreatedAt(videoId);

			// Turn milliseconds difference into hours
			let millisecondsDifference = Math.abs(dateNowMilliseconds - videoProgressCreatedAt);
			let hoursDifference = millisecondsDifference / (60 * 60 * 1000);

			if (hoursDifference >= hoursThreshold) {
				sessionStorage.removeItem(key);
			}
		}
	}


}
