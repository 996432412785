import { createRef, useEffect, useRef } from "react";
import styles from "./lessonNavigation.module.scss";
import cn from "classnames";
import React from "react";
import ProgressBar from "../progressBar/ProgressBar";
import { CurrentSection, Navigation, SectionsList, abortLessonFunction, setLessonCompleteFunction, setSectionCompleteFunction } from "@/Interfaces";
import { useTranslation } from "react-i18next";

interface LessonNavigationProps {
    sectionsList?: SectionsList,
    currentSection: CurrentSection,
    setCurrentSection: React.Dispatch<React.SetStateAction<CurrentSection>>,
    navigation: Navigation,
    setNavigation: React.Dispatch<React.SetStateAction<Navigation>>,
    canContinue?: boolean,
    setSectionComplete?: setSectionCompleteFunction;
    setLessonComplete?: setLessonCompleteFunction;
    abortLesson?: abortLessonFunction;
    lessonType?: number;
    progress?: number;
    setFeedback?: (feedback: string | null) => void;
    saveAnswer?: () => void;
}
interface ChainPartIcon {
    [key: number]: string;
};

const LessonNavigationBar: React.FC<LessonNavigationProps> = ({
    sectionsList,
    currentSection,
    setCurrentSection,
    navigation,
    setNavigation,
    canContinue,
    setSectionComplete,
    setLessonComplete,
    abortLesson,
    lessonType,
    progress,
    setFeedback,
    saveAnswer }) => {

    const CHAIN_PART_ICON: ChainPartIcon = {
        1: 'fact_check',
        2: 'live_tv',
        3: 'auto_stories',
        4: 'auto_stories',
    };
    const refs = useRef(sectionsList?.sections?.map(() => createRef()) as React.RefObject<HTMLButtonElement>[]);
    const { t } = useTranslation();

    // Set navigation index's accordin to completed sections
    useEffect(() => {
        let current = 0;
        let next = 1;
        let previous = -1;

        if (sectionsList && sectionsList.sections) {
            for (let i = 0; i < sectionsList.sections.length; i++) {
                const section = sectionsList.sections[i];
                if (section.completedAt) {
                    current = i + 1;
                    next = i + 2;
                    previous = i;

                }
            }
        }

        setNavigation({
            ...navigation,
            index: {
                current: current,
                next: next,
                previous: previous,
            }
        });
    }, [sectionsList?.sections]);

    useEffect(() => {
        if (navigation.index && sectionsList) {
            setCurrentSection({
                ...currentSection,
                section: sectionsList.sections[navigation.index.current]
            });

            scrollInto();
            setNavigation({
                ...navigation,
                previousButtonDisabled: lessonType !== 3 && navigation.index.current <= sectionsList.sectionsFirstIndex ? true : false, // Set previous-button disabled if in first section
                // nextButtonDisabled: navigation.index.current >= sectionsList.sectionsLastIndex ? true : false // Set next-button disabled if in last section
            });
        }
    }, [navigation.index]);

    useEffect(() => {
        const { index } = navigation;
        const { childSectionIndex } = navigation;

        if (index && childSectionIndex && sectionsList) {
            setNavigation({
                ...navigation,
                previousButtonDisabled: (lessonType !== 3 && index.current === sectionsList.sectionsFirstIndex && childSectionIndex?.current === 0) ? true : false, // Set previous-button disabled if in first child section
            });
        }
    }, [navigation.childSectionIndex?.current]);

    useEffect(() => {
        if (hasChildSections()) {
            setNavigation({
                ...navigation,
                childSectionIndex: {
                    current: 0,
                    next: 1,
                    previous: -1,
                    last: getLastChildSectionIndex()
                }
            });
        }
    }, [currentSection.sectionData]);

    const setSection = (index: number) => {
        setNavigation({
            ...navigation,
            index: {
                current: index,
                next: index + 1,
                previous: index - 1
            }
        });
    };

    const next = () => {
        const { childSectionIndex, index } = navigation;

        if (!canContinue && setFeedback) {
            const { sectionData } = currentSection;
            setFeedback(sectionData?.mustAnswerCorrectlyToQuestionToContinue ? t('answerCorrectToContinue') : t('answerToContinue'));
            return;
        }
        if (hasChildSections() && !isLastChildSection() && childSectionIndex) {
            setNavigation({
                ...navigation,
                childSectionIndex: {
                    ...childSectionIndex,
                    current: childSectionIndex.next,
                    next: childSectionIndex.next + 1,
                    previous: childSectionIndex.previous + 1,
                }
            });
            return;
        }

        if (index) {
            // Set section completed if it hasn't any, or the current childsection is last one And the section isn't yet completed.
            if (setSectionComplete && (!hasChildSections() || isLastChildSection()) && !isSectionComlpeted(index.current)) {
                setSectionComplete(index.current);
            }

            // Check whether to show question form summary
            if (showQuestionFormSummary() && childSectionIndex) {
                setNavigation({
                    ...navigation,
                    childSectionIndex: {
                        ...childSectionIndex,
                        current: childSectionIndex.next,
                        next: childSectionIndex.next + 1,
                        previous: childSectionIndex.previous + 1,
                    }
                });
                return;
            }

            // continue to next section, if the current one isn't the last.
            if (!isLastSection()) {
                setNavigation({
                    ...navigation,
                    index: {
                        current: index.next,
                        next: index.next + 1,
                        previous: index.previous + 1
                    }
                });
                return;
            }

            // Set lesson complete if current section is the last one and it's completed. Else continue to the next section
            if (setLessonComplete && isLastSection()) {
                setLessonComplete();
            }
        }
    };

    const previous = () => {
        const { childSectionIndex } = navigation;
        const { index } = navigation;

        if (hasChildSections() && !isFirstChildSection() && childSectionIndex) {
            setNavigation({
                ...navigation,
                childSectionIndex: {
                    ...childSectionIndex,
                    current: childSectionIndex.previous,
                    next: childSectionIndex.next - 1,
                    previous: childSectionIndex.previous - 1,
                }
            });
        } else {
            if (index) {
                setNavigation({
                    ...navigation,
                    index: {
                        ...navigation.index,
                        current: index.previous,
                        next: index.next - 1,
                        previous: index.previous - 1
                    }
                });
            }
        }
    };

    const scrollInto = () => {
        const { index } = navigation;

        if (index) {
            const currentRef = refs.current[index.current];

            if (currentRef && currentRef.current) {
                (currentRef.current as HTMLElement).scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center',
                });
            }
        }
    };

    const hasChildSections = (): boolean => {
        if (currentSection.sectionData && currentSection.sectionData.questions) return true;
        if (currentSection.sectionData && currentSection.sectionData.pages) return true;
        return false;
    };

    const isLastChildSection = (): boolean => {
        const { childSectionIndex } = navigation;
        if (childSectionIndex) {
            return childSectionIndex.current >= childSectionIndex.last;
        }
        return true;
    };

    const isFirstChildSection = (): boolean => {
        const { childSectionIndex } = navigation;
        return childSectionIndex?.current === 0;
    };

    const isLastSection = (): boolean => {
        if (!sectionsList) return false;

        const { index } = navigation;
        const { sections } = sectionsList;

        return (index?.current) === (sections?.length - 1);

    };

    const getLastChildSectionIndex = (): number => {
        const { sectionData } = currentSection;
        if (sectionData?.questions) return sectionData?.questions.length > 0 ? sectionData?.questions.length - 1 : 0;
        if (sectionData?.pages) return sectionData?.pages.length > 0 ? sectionData?.pages.length - 1 : 0;
        return 0;
    };

    const isSectionComlpeted = (index: number): boolean => {
        const section = sectionsList?.sections[index];
        return !!section?.completedAt;
    };

    const showQuestionFormSummary = () => {
        const { childSectionIndex } = navigation;
        if (childSectionIndex) {
            return currentSection.sectionData?.showQuestionAnswerFeedback && childSectionIndex.current === childSectionIndex.last;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.elessonNavigationContainer}>
                <div className={styles.navigationButtonContainer}>
                    <button
                        id="previousButton"
                        onClick={lessonType !== 3 ? previous : abortLesson}
                        className={styles.elessonNavigationButton}
                        disabled={navigation.previousButtonDisabled}
                    >
                        <span className="material-symbols-rounded q_white fs-32">
                            {lessonType !== 3 ? 'chevron_left' : 'close'}
                        </span>
                    </button>
                </div>
                {lessonType !== 3
                    ? <div className={styles.chainContainer}>
                        <div id="chain" className={styles.chain}>
                            {sectionsList?.sections && sectionsList?.sections.map((section, index) => (
                                <button
                                    key={index}
                                    ref={refs.current[index]}
                                    onClick={() => setSection(index)}
                                    disabled={!section.completedAt ? true : false}
                                    className={cn(styles.chainButton, {
                                        [styles.completed]: section.completedAt,
                                        [styles.active]: section.orderingNumber === currentSection.section?.orderingNumber
                                    })}>
                                    <span className={section.type == 2 ? "material-symbols-rounded no-fill fs-20" : "material-symbols-rounded fs-20"}>
                                        {CHAIN_PART_ICON[section.type]}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </div>
                    : <ProgressBar progress={progress ?? 0} />
                }
                <div className={styles.navigationButtonContainer}>
                    <div id="nextButtonTimer" >
                        {canContinue ?
                            <button
                                id="nextButton"
                                onClick={next}
                                className={styles.elessonNavigationButton}
                                disabled={navigation.nextButtonDisabled}
                            >
                                <span className="material-symbols-rounded q_white fs-32">
                                    chevron_right
                                </span>
                            </button>
                            :
                            <button className={styles.answerButton} onClick={saveAnswer}>{t("saveAnswer")}</button>
                        }
                    </div>
                </div>
            </div >


            {/* TODO: Section timer component */}
            <div id="sectionTimer" hidden>
                <small> <span className="section-time-left"></span></small>
            </div>
        </div>
    );
};

export default LessonNavigationBar;