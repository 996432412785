import { useEffect, useRef, useState } from "react";
import styles from "./modal.module.scss";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import Markdown from "marked-react";

interface NotificationModalProps {
    title: string;
    content: string;
    show: boolean;
}

const NotificationModal: React.FC<NotificationModalProps> = ({ title, content, show }) => {
    const [showNotification, setShowNotification] = useState<boolean>(show);
    const ref = useRef(null) as React.RefObject<any>;
    const { t } = useTranslation();

    useEffect(() => {
        const handleOutSideClick = (event: { target: any; }) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                setShowNotification(false);
            }
        };

        if (!showNotification) {
            window.removeEventListener("mousedown", handleOutSideClick);
        } else {
            window.addEventListener("mousedown", handleOutSideClick);
        }

        return () => {
            window.removeEventListener("mousedown", handleOutSideClick);
        };

    }, [show]);

    return (
        <>
            {showNotification &&
                <div className={styles.backdrop}>
                    <div ref={ref} className={cn(styles.modal, styles.dialog)}>
                        <div className={styles.header}>
                            <button onClick={() => setShowNotification(false)}>
                                <span className="material-symbols-rounded">close</span>
                            </button>
                        </div>
                        <div className={styles.body}>
                            <h6>
                                {title}
                            </h6>

                            <div>
                                <Markdown>{content}</Markdown>
                            </div>
                            <div className={styles.hemmo}>
                                <img src="/assets/hemmo/hemmo_1.svg" alt="" height={100} width={100} />
                            </div>
                            <div className={styles.footer}>
                                <button onClick={() => setShowNotification(false)} className={styles.next}>{t("close")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};


export default NotificationModal;