import styles from "@/components/sections/questionForm/questionForm.module.scss";
import ReaderButton from "@/components/readerButton/ReaderButton";
import Markdown from "marked-react";

interface InstructionProps {
    question: any;
}

const Instruction: React.FC<InstructionProps> = ({ question }) => {
    return (
        <>
        </>
    );
};

export default Instruction;