import { Link } from "react-router-dom";
import styles from "./menuButton.module.scss";
import cn from "classnames";

interface MenuButtonProps {
    title: string;
    subTitle?: string;
    progress?: number;
    icon?: string;
    image?: string;
    backgroundImage?: string;
    link: string;
    border?: boolean;
    disabled?: boolean;
    color?: string
}

const MenuButton: React.FC<MenuButtonProps> = ({ title, subTitle, progress, icon, image, backgroundImage, link, border, disabled, color }) => {
    const inlineStyle = {
        backgroundImage: `url(${backgroundImage}`,
        color: `var(--${color})`
    }
    
    return (
        <Link to={link} className={cn(styles.button, {
            [styles.border]: border,
            [styles.disabled]: disabled
        })} style={inlineStyle}>
            <div className={styles.content}>
                {icon
                    ? <span className={styles.iconBackground}>
                        <span id="q_icon" className="material-symbols-rounded q_white">{icon}</span>
                    </span>
                    : image &&
                    <div className={styles.imageContainer}>
                        <img src={image} alt="" width="48px" height="48px" />
                    </div> 
                }
                <div>
                    <b>{title}</b>
                    <br />
                    {subTitle && subTitle}
                </div>
                {(progress !== undefined && !disabled) &&
                    <div className={styles.progress}>{progress} %</div>
                }
            </div>
        </Link>
    );
};

export default MenuButton;