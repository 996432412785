import { BookPopup, togglePopupFunction } from "@/Interfaces";
import Markdown from "marked-react";
import styles from "./book.module.scss";
import cn from "classnames";
import useScrollOnRender from "@/hooks/useScrollOnRender";

interface PopOverProps {
    popover: BookPopup;
    togglePopup: togglePopupFunction;
}

const PopOver: React.FC<PopOverProps> = ({ popover, togglePopup }) => {
    const ref = useScrollOnRender(popover);

    return (
        <div ref={ref} className={cn(styles.paragraph, styles.popover)}>
            <button onClick={() => togglePopup({item: popover, index: null})}>
                <span className="material-symbols-rounded q_white">
                    close
                </span>
            </button>
            <Markdown>{popover.title}</Markdown>
            <Markdown>{popover.text}</Markdown>
        </div>
    );
};

export default PopOver;