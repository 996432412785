import { SummaryQuestion } from "@/Interfaces";
import styles from "./accordion.module.scss";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useRef, useState } from "react";
import ImageContainer from "../image/ImageContainer";
import { TASK_MEDIA_FILE_PATH } from "@/constants";

interface AccordionProps {
    accordionItems: SummaryQuestion[];
    itemCount: number;
}

interface AccordionItemProps {
    index: number,
    accordionItem: SummaryQuestion;
    itemCount: number;
    isOpen: boolean;
    onClick: any;

}

const AccordionItem: React.FC<AccordionItemProps> = ({ index, accordionItem, itemCount, isOpen, onClick }) => {
    const { t } = useTranslation();
    const contentHeight = useRef() as React.RefObject<HTMLDivElement>;
    const { studentAnswer } = accordionItem;

    return (
        <div className={styles.accordionItem}>
            <div className={cn(styles.button, {
                [styles.open]: isOpen
            })} onClick={onClick}>
                <div className={styles.title}>
                    <b>{t('task')} {index}/{itemCount}</b>
                    <small className={cn({
                        [styles.correct]: studentAnswer.isCorrect
                    })}>{studentAnswer.isCorrect ? t('correct') : t('incorrect')}</small>
                </div>
            </div>

            <div ref={contentHeight} className={styles.body} style={
                isOpen
                    ? { height: contentHeight.current?.scrollHeight }
                    : { height: "0px" }
            }>
                <div>
                    <div className={styles.question}>
                        {accordionItem.question}
                        {accordionItem.file &&
                            <ImageContainer src={`${TASK_MEDIA_FILE_PATH[accordionItem.file.fileLocation]}${accordionItem.file.fileName}`} questionImage zoom />
                        }
                    </div>
                    <div className={cn(styles.answer, {
                        [styles.correct]: studentAnswer.isCorrect
                    })}>
                        {studentAnswer.isCorrect ? t('correct') : t('incorrect')}
                    </div>
                </div>
            </div>
        </div>
    );

};

const Accordion: React.FC<AccordionProps> = ({ accordionItems, itemCount }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleItemClick = (index: number) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className={styles.container}>
            {accordionItems.map((item, index) => (
                <AccordionItem
                    key={index}
                    accordionItem={item}
                    index={(index + 1)}
                    itemCount={itemCount}
                    isOpen={activeIndex === index}
                    onClick={() => handleItemClick(index)} />
            ))}
        </div>
    );
};

export default Accordion;