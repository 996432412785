import { Answer, Question } from "@/Interfaces";
import styles from "@/components/sections/questionForm/questionForm.module.scss";
import Markdown from "marked-react";
import cn from "classnames";
import ReaderButton from "@/components/readerButton/ReaderButton";
import ImageContainer from "@/components/image/ImageContainer";
import { useCallback, useEffect } from "react";
import "yet-another-react-lightbox/styles.css";
import ZoomButton from "@/components/zoomButton/ZoomButton";


interface RadioQuestionProps {
    question: Question | null,
    answer: Answer | null,
    answerArr: string[],
    setAnswerArr: (arr: string[]) => void;
}

const RadioQuestion: React.FC<RadioQuestionProps> = ({ question, answer, answerArr, setAnswerArr }) => {
    const fileUrls = useCallback(() => {
        let urls = [];

        if (question && question.choices) {
            urls = Object.entries(question.choices).map(([i, choice]) => {
                if (choice.fileUrl) {
                    return choice.fileUrl;
                }
            });
        }

        return urls;
    }, [question?.choices]);


    useEffect(() => {
        if (answer) {
            setAnswerArr(answer.answer);
        }
    }, []);

    const addAnswer = (value: string) => {
        setAnswerArr([value]);
    };

    return (
        question &&
        <>
            {question.choices &&
                <div className={styles.answers}>
                    {Object.entries(question.choices).map(([i, choice]) => (
                        <div key={i} className={styles.answerContainer}>
                            <button id={`answer-${i}`} onClick={() => addAnswer(i)} className={cn(styles.answer, {
                                [styles.active]: answerArr.includes(i),
                                [styles.correct]: answer && answer.answer.includes(i) && answer.isCorrect === true,
                                [styles.incorrect]: answer && answer.answer.includes(i) && answer.isCorrect === false
                            })}>
                                <div>
                                    {choice.fileUrl && <ImageContainer src={choice.fileUrl} answerChoise />}
                                    <Markdown>{choice}</Markdown>
                                </div>
                            </button>
                            {choice.fileUrl
                                ? <ZoomButton urls={fileUrls()} />
                                : <ReaderButton text={choice} />
                            }
                        </div>
                    ))}
                </div>
            }
        </>
    );
};

export default RadioQuestion;