import { useRef, useState } from "react";
import Accordion from "../accordion/Accordion";
import cn from "classnames";
import { SummaryQuestion } from "@/Interfaces";
import styles from './summaryDropdown.module.scss';
interface SummaryDropdownProps {
    title: string;
    questions: SummaryQuestion[];
    totalCount: number
}

const SummaryDropdown: React.FC<SummaryDropdownProps> = ({ title, questions, totalCount }) => {
    const [open, setOpen] = useState<boolean>(false);
    const contentHeight = useRef() as React.RefObject<HTMLDivElement>;

    return (
        <div className={styles.dropdownContainer}>
            <div className={cn(styles.dropdownButton, {
                [styles.open]: open
            })} onClick={() => setOpen(!open)}>
                <h4>{title}</h4>
            </div>
            <div ref={contentHeight} className={styles.content} style={
                open
                    ? { maxHeight: `${(contentHeight.current?.scrollHeight ?? 0) * 3}px` } // TODO: optimize way to calculate max height due to dynamic content
                    : { maxHeight: "0px" }
            }>
                <Accordion accordionItems={questions} itemCount={totalCount} />
            </div>
        </div>
    );
};

export default SummaryDropdown;