// Define globally used constants here

export const TASK_MEDIA_FILE_PATH: { [key: number]: string; } = {
    1: import.meta.env.VITE_TASK_MEDIA_BASE_1,
};

export const BOOK_MEDIA_FILE_PATH: { [key: string]: string; } = {
    background: import.meta.env.VITE_BOOK_MEDIA_BASE,
};

export const LESSON_GROUP_TYPE: { [key: number]: string; } = {
    1: 'basic',
    2: 'extra',
    3: 'exam'
};