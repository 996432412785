import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';

interface AudioContextProps {
    playAudio: (audioElement: HTMLAudioElement) => void;
    pauseAudio: (audioElement?: HTMLAudioElement) => void;
    currentAudio: HTMLAudioElement | null;
}

const AudioContext = createContext<AudioContextProps | undefined>(undefined);

interface AudioProviderProps {
    children: ReactNode;
}

export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
    const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);

    const playAudio = useCallback((audioElement: HTMLAudioElement) => {
        if (currentAudio && currentAudio !== audioElement) {
            currentAudio.pause();
        }
        setCurrentAudio(audioElement);
        audioElement.play();
    }, [currentAudio]);

    const pauseAudio = useCallback((audioElement?: HTMLAudioElement) => {
        if (audioElement) {
            audioElement.pause();
            return;
        }

        if (currentAudio) {
            currentAudio.pause();
            setCurrentAudio(null);
        }
    }, [currentAudio]);

    return (
        <AudioContext.Provider value={{ playAudio, pauseAudio, currentAudio }}>
            {children}
        </AudioContext.Provider>
    );
};

export const useAudio = (): AudioContextProps => {
    const context = useContext(AudioContext);
    if (context === undefined) {
        console.error('useAudio must be used within an AudioProvider');
        throw new Error();
    }
    return context;
};
