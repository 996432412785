import styles from "@/components/sections/questionForm/questionForm.module.scss";
import { Answer, Question } from "@/Interfaces";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface TextQuestionProps {
    question: Question | null;
    answer: Answer | null,
    answerArr: string[],
    setAnswerArr: (arr: string[]) => void;
}

const TextQuestion: React.FC<TextQuestionProps> = ({ question, answer, answerArr, setAnswerArr }) => {
    const [count, setCount] = useState<number>(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (answer) {
            setAnswerArr(answer.answer);
            setCount(answer.answer[0].length);
        }
    }, []);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setAnswerArr([e.target.value]);
        setCount(e.target.value.length);
    };

    return (
        question &&
        <div className={styles.answers}>
            <textarea spellCheck="false" minLength={1} maxLength={500} value={answerArr} placeholder={t('answerHere')} className={styles.answer} onChange={handleChange}></textarea>
            <div className={styles.characterCount}>
                <span>{count}/500</span>
            </div>
        </div>
    );
};

export default TextQuestion;