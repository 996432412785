import { BookDialog, BookPart, toggleDialogFunction } from "@/Interfaces";
import Markdown from "marked-react";
import styles from "./book.module.scss";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import VideoContainer from "@/components/video/videoContainer";
import useScrollOnRender from "@/hooks/useScrollOnRender";

interface DialogProps {
    dialogs: BookDialog[];
    part: BookPart;
    iconList: { [key: string]: string; };
    setDialogRef: React.Dispatch<React.SetStateAction<any>>;
}

const Dialog: React.FC<DialogProps> = ({ dialogs, part, iconList, setDialogRef }) => {
    const [dialog, setDialog] = useState<BookDialog | null>(null);
    const [arrowDialogIndex, setArrowDialogIndex] = useState<number>(0);
    const [dialogIndex, setDialogIndex] = useState<number | null>(null);
    const ref = useScrollOnRender(dialog);

    useEffect(() => {
        const arrowDialog = getArrowDialog();

        if (arrowDialog) {
            const { pages } = arrowDialog;

            if (pages) setDialog(pages[arrowDialogIndex]);
        }

        return () => {
            setDialog(null);
            setArrowDialogIndex(0);
        };
    }, [part]);

    useEffect(() => {
        setDialogRef(dialog);
    }, [dialog]);


    const toggleDialog: toggleDialogFunction = (params) => {
        const { item } = params;
        const { index } = params;

        if (index === null || index === dialogIndex) {
            setDialog(null);
            setDialogIndex(null);
        } else {
            setDialog(item);
            setDialogIndex(index);
        }
    };

    const nextDialog = (dialog: BookDialog) => {
        const { pages } = dialog;
        if (arrowDialogIndex === null) return;

        let index = arrowDialogIndex + 1;

        if (pages) {
            setArrowDialogIndex(index);
            setDialog(pages[index]);
        }
    };

    const previousDialog = (dialog: BookDialog) => {
        const { pages } = dialog;
        if (arrowDialogIndex === null) return;

        let index = arrowDialogIndex - 1;

        if (pages) {
            setArrowDialogIndex(index);
            setDialog(pages[index]);
        }
    };

    const getArrowDialog = () => {
        return dialogs?.find(dialog => dialog.type === 2) ?? null;
    };

    return (
        <>
            <div className={styles.dialogButtons}>
                {dialogs.map((dialog, index) => (
                    dialog.type === 1 ?
                        <button key={index} onClick={() => toggleDialog({ item: dialog, index })} className={cn({
                            [styles.circle]: dialog.icon,
                            [styles.pill]: dialog.title
                        }
                        )}>
                            {dialog.icon
                                ? <span className="material-symbols-rounded q_white">
                                    {iconList[dialog.icon]}
                                </span>
                                : <span>{dialog.title}</span>
                            }
                        </button>
                        : (dialog.type === 2 && dialog.pages) &&
                        <div key={index} className={styles.dialogCounter}>
                            <button onClick={() => previousDialog(dialog)} className={styles.circle} disabled={arrowDialogIndex === 0 && true}>
                                <span className="material-symbols-rounded q_white">
                                    chevron_left
                                </span>
                            </button>
                            <span>{arrowDialogIndex + 1}/{dialog.pagesCount}</span>
                            <button onClick={() => nextDialog(dialog)} className={styles.circle} disabled={arrowDialogIndex === (dialog.pages.length - 1) && true}>
                                <span className="material-symbols-rounded q_white">
                                    chevron_right
                                </span>
                            </button>
                        </div>
                ))}
            </div >
            {dialog &&
                <div ref={ref} className={cn(styles.paragraph, styles.dialog)}>
                    <Markdown>{dialog.title}</Markdown>
                    {dialog.video && <VideoContainer src={dialog.video} fileLocationType={4} />}
                    <Markdown>{dialog.text}</Markdown>
                </div>
            }
        </>
    );
};


export default Dialog;