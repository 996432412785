import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Start, { startLessonAction } from "@/views/start/Start";
import ErrorPage from "@/views/error/ErrorPage";
import Lesson, { lessonActions, lessonLoader } from "@/views/lesson/Lesson";
import Root from "@/views/root/Root";
import Completed, { lessonCompletedLoader } from "./views/completed/Completed";
import LessonRoot, { lessonRootLoader } from "./views/lesson/lessonRoot/LessonRoot";
import PortalRoot, { portalRootLoader } from "./views/portal/portalRoot/PortalRoot";
import Landing from "./views/portal/landing/Landing";
import Group, { groupLoader } from "./views/portal/group/Group";
import * as Sentry from "@sentry/react";
import Material, { materialLoader } from "./views/material/Material";
import Trophies, { trophiesLoader } from "./views/portal/trophies/Trophies";

const App = () => {
  //Remove this comment if you feel like it
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const router = sentryCreateBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [{
        path: "/portal",
        element: <PortalRoot />,
        id: "portal",
        loader: portalRootLoader,
        children: [
          {
            path: "/portal/:courseId/:drivingLicense",
            element: <Landing />,
          },
          {
            path: "/portal/:courseId/:drivingLicense/group/:id",
            element: <Group />,
            loader: groupLoader,
          },
          {
            path: "/portal/:courseId/:drivingLicense/trophies",
            element: <Trophies />,
            loader: trophiesLoader,
          }
        ]
      },
      {
        path: "/lesson",
        element: <LessonRoot />,
        id: "lesson",
        loader: lessonRootLoader,
        children: [
          {
            path: "/lesson/:id",
            element: <Start />,
            action: startLessonAction,
          },
          {
            path: "/lesson/:completionId/play/",
            element: <Lesson />,
            loader: lessonLoader,
            action: lessonActions,
          },
          {
            path: "/lesson/:completionId/completed",
            element: <Completed />,
            loader: lessonCompletedLoader,
          }
        ]
      },
      {
        path: "/material/:bookId",
        element: <Material />,
        loader: materialLoader,
      }]
    }
  ]);

  return (
    <RouterProvider router={router} />
  );
};

export default App;
